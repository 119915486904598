<template>
	<div class="financing">
		<ori-header />

		<!-- <news-card /> -->

		<news-nav />

		<router-view />
		<policy-card />

		<result-card style="padding-bottom:40px" />
		<page-foot />
	</div>
</template>

<script>
// import NewsCard from "../../../components/ori-com/NewsCard.vue";
import NewsNav from '../../../components/ori-com/NewsNav.vue'
import PolicyCard from '../../../components/ori-com/PolicyCard.vue'
import ResultCard from '../../../components/ori-com/ResultCard.vue'
import PageFoot from '../../../components/ori-com/PageFoot.vue'

export default {
	components: {
		PageFoot,
		// NewsCard,
		NewsNav,
		PolicyCard,
		ResultCard,
	},
}
</script>

<style lang="scss" scoped>
// .financing {
//   background: #f9f9f9;
// }
</style>
