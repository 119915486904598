<template>
  <ori-card-wrap
    class="policy-card"
    zhTitle="政策库"
    enTitle="Policy Library"
    desc="10000+最新惠企政策实时推送"
  >
    <span slot="more">查看更多></span>
    <template>
      <ul class="policy-list">
        <li v-for="(i, index) in list" :key="i.id" v-show="index < 8">
          <div class="date">
            <span class="day">
              {{ i.day }}
            </span>
            <span class="year-month">
              {{ i.yearMonth }}
            </span>
          </div>
          <div class="title-desc">
            <div class="title solo-row hover-red">
              {{ i.title }}
            </div>
            <div class="desc solo-row">
              {{ i.desc }}
            </div>
          </div>
        </li>
      </ul>
    </template>
  </ori-card-wrap>
</template>

<script>
import { policyData } from "@/Mock";
export default {
  name: "policy-card",
  data() {
    return {
      list: policyData.list,
    };
  },
};
</script>

<style lang="scss" scoped>
.policy-card {
  .policy-list {
    padding: 0;
    margin: 0;
    list-style: none;

    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    li + li {
      border-top: 1px dashed #f4f5f9;
    }

    li:nth-child(2n) {
      margin-left: 20px;
    }

    li {
      cursor: pointer;
      width: 590px;
      height: 70px;
      padding: 8px 20px 0 0;
      background-color: #fff;
      display: flex;
      // align-items: flex-start;
      .date {
        flex: 0 0 auto;
        width: 60px;
        text-align: center;
        border-right: 1px solid #f4f5f9;
        .day {
          display: block;
          font-size: 25px;
          font-weight: 700;
          color: #333333;
          line-height: 38px;
        }
        .year-month {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          line-height: 18px;
        }
      }

      .title-desc {
        margin-left: 7px;
        overflow: hidden;
        .title {
          width: 500px;
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          margin-top: 5px;
        }
        .desc {
          margin-top: 5px;
          width: 500px;
          height: 16px;
          font-size: 12px;
          font-weight: 400;
          text-align: left;
          color: #666666;
          line-height: 18px;
        }
      }
    }
  }
}
</style>