<template>
  <ori-card-wrap
    class="result-card"
    zhTitle="成果转化"
    enTitle="Achievement transformation"
    desc="快速发布成果转化需求，AI智能匹配，担保交易"
  >
    <span slot="more">查看更多></span>
    <template>
      <ul class="result-card">
        <li v-for="(i, index) in list" :key="i.id" v-show="index < 6">
          <div class="top">
            <div class="title solo-row">
              {{ i.title }}
            </div>
            <div class="tags">
              <span>{{ i.typeTag }}</span>
            </div>
          </div>
          <div class="city-pt">
            <div class="city">
              <span> 地域要求： </span>
              <span>
                {{ i.city }}
              </span>
            </div>
            <div class="pt">
              <span> 终端版本： </span>
              <span>
                {{ i.ptTag }}
              </span>
            </div>
          </div>

          <div class="price">
            ￥<span>{{ i.lowPrice }}</span> ~ ￥<span>{{ i.heightPrice }}</span>
          </div>

          <span class="line"></span>
        </li>
      </ul>
    </template>
  </ori-card-wrap>
</template>

<script>
import { resultData } from "@/Mock";
export default {
  name: "result-card",
  data() {
    return {
      list: resultData.list,
    };
  },
};
</script>

<style lang="scss" scoped>
.result-card {
  .result-card {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    flex-wrap: wrap;

    li:hover,
    li:active {
      box-shadow: 0px 0px 20px 0px rgb(206 206 206 / 17%);
      transform: translateY(-5px);
    }

    li {
      cursor: pointer;
      position: relative;
      padding: 0 20px;
      width: 389px;
      height: 229px;
      background: #ffffff;
      transition: all 0.5s ease-in;
      margin-right: 15px;
    }

    li:nth-child(3n) {
      margin-right: 0;
    }

    li:nth-child(n + 4) {
      margin-top: 20px;
    }

    li {
      .line {
        position: absolute;
        top: 0;
        left: 20px;
        z-index: 2;
        display: block;
        width: 60px;
        height: 4px;
        background: #e62d31;
      }
      .top {
        padding-top: 28px;
        padding-bottom: 20px;
        border-bottom: 1px solid #f4f5f9;
        .title {
          width: 350px;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
        }
        .tags {
          margin-top: 15px;
          span {
            display: inline-block;
            height: 26px;
            line-height: 26px;
            padding: 0 14px;
            background: #f4f5f9;
            border-radius: 3px;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            margin-right: 3px;
          }
        }
      }

      .city-pt {
        & > div {
          margin: 0;
          width: 350px;
          font-size: 12px;
          font-weight: 400;
          text-align: left;
          line-height: 28px;

          span {
            color: #333;
          }

          span:nth-child(1) {
            color: #999;
          }
        }
      }

      .price {
        margin: 0;
        margin-top: 15px;
        font-size: 18px;
        font-weight: 700;
        color: #e62d31;
      }
    }
  }
}
</style>