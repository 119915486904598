<template>
	<div class="news-nav shadow">
		<div class="news-nav-content">
			<div class="news-nav-box" @click="$router.push('/ori-financing/home')">
				<i class="el-icon-news"></i>
				首页
			</div>

			<div
				class="news-nav-box"
				@click="$router.push('/ori-financing/short-news')"
			>
				<i class="el-icon-chat-line-square"></i>
				创业辅导

				<div class="more-nav">
					<div class="nav-item">找创业项目</div>
					<div class="nav-item">找创投机构</div>
					<div class="nav-item">创投对接活动</div>
					<div class="nav-item">商业计划书指导</div>
				</div>

				<span class="pull-down">
					<i class="el-icon-arrow-down"></i>
				</span>
			</div>

			<div
				class="news-nav-box"
				@click="$router.push('/ori-financing/short-news')"
			>
				<i class="el-icon-receiving"></i>
				创业培训
			</div>

			<div
				class="news-nav-box"
				@click="$router.push('/ori-financing/short-news')"
			>
				<i class="el-icon-view"></i>
				创投视界
			</div>

			<div
				class="news-nav-box"
				@click="$router.push('/ori-financing/short-news')"
			>
				<i class="el-icon-folder-opened"></i>
				会员服务
			</div>

			<div
				class="news-nav-box"
				@click="$router.push('/ori-financing/short-news')"
			>
				<i class="el-icon-collection"></i>
				快讯
			</div>

			<div class="news-nav-box">
				<div @click="$router.push('/ori-financing/newest')">
					<i class="el-icon-chat-dot-round"></i>
					资讯
					<span class="pull-down">
						<i class="el-icon-arrow-down"></i>
					</span>
				</div>
				<div class="more-nav">
					<div class="nav-item" @click="$router.push('/ori-financing/newest')">
						最新
					</div>
					<div
						class="nav-item"
						@click="$router.push('/ori-financing/recommended')"
					>
						推荐
					</div>
					<div
						class="nav-item"
						@click="$router.push('/ori-financing/ventureCapital')"
					>
						创投
					</div>
					<div
						class="nav-item"
						@click="$router.push('/ori-financing/economics')"
					>
						财金
					</div>
					<div class="nav-item" @click="$router.push('/ori-financing/car')">
						汽车
					</div>
					<div
						class="nav-item"
						@click="$router.push('/ori-financing/technology')"
					>
						科技
					</div>
					<div
						class="nav-item"
						@click="$router.push('/ori-financing/companiesTake')"
					>
						企服
					</div>
					<div class="nav-item" @click="$router.push('/ori-financing/life')">
						生活
					</div>
					<div
						class="nav-item"
						@click="$router.push('/ori-financing/innovation')"
					>
						创新
					</div>
					<div
						class="nav-item"
						@click="$router.push('/ori-financing/realEstate')"
					>
						房产
					</div>
					<div
						class="nav-item"
						@click="$router.push('/ori-financing/workplace')"
					>
						职场
					</div>
					<div
						class="nav-item"
						@click="$router.push('/ori-financing/enterprise')"
					>
						企业号
					</div>
					<div class="nav-item" @click="$router.push('/ori-financing/other')">
						其他
					</div>
				</div>
			</div>

			<div class="news-nav-box" @click="$router.push('/ori-financing/live')">
				<i class="el-icon-mic"></i>
				直播
			</div>
			<div class="news-nav-box" @click="$router.push('/ori-financing/video')">
				<i class="el-icon-video-camera"></i> 视频
			</div>
			<div class="news-nav-box" @click="$router.push('/ori-financing/topic')">
				<i class="el-icon-cpu"></i>
				专题
			</div>
			<div
				class="news-nav-box"
				@click="$router.push('/ori-financing/activity')"
			>
				<i class="el-icon-connection"></i>
				活动
			</div>
		</div>
		<div class="logo-wrap">
			<div class="img">
				<img :src="require('@/assets/images/in-peak.png')" alt="" srcset="" />
			</div>
			<div class="desc">
				<div class="title">应必客</div>
				<div class="url">in-peak.com</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'news-nav',
}
</script>

<style lang="scss" scoped>
.news-nav {
	position: fixed;
	left: 6%;
	top: 100px;
	z-index: 999;

	background: #fafafa;
	border-radius: 20px;

	.logo-wrap {
		display: flex;
		padding: 20px;

		.img {
			margin-right: 10px;
			width: 60px;
			img {
				width: 60px;
			}
		}

		.desc {
			text-align: center;
			.title {
				font-size: 24px;
			}
		}
	}

	.news-nav-content {
		padding: 10px;

		.news-nav-box {
			position: relative;
			margin-top: 20px;
			width: 180px;
			cursor: pointer;
			padding: 8px 15px;
			border-radius: 30px;
			transition: all 0.2s ease-in;
			&:hover,
			&:active,
			&.active {
				background: #999;
				color: #fff;
				.more-nav {
					height: 330px;
					width: 60%;
					border-width: 1px;
				}
			}

			i {
				margin-right: 4px;
				// font-weight: bold;
			}

			.more-nav {
				font-size: 14px;
				z-index: 99;
				background: #fff;
				position: absolute;
				top: 100%;
				left: 50%;
				width: 50%;
				height: 0;

				overflow: hidden;

				transition: all 0.15s ease-in;

				border-radius: 4px;
				border: 1px solid #ededed;
				border-width: 0;

				color: #333;

				.nav-item {
					display: inline-block;
					width: 100%;
					padding: 2px 10px;
					text-align: center;

					&:hover,
					&:active,
					&.active {
						background: #999;
						color: #fff;
					}
				}
			}

			.pull-down {
				z-index: 99;
				float: right;
			}
		}

		.collapse {
			width: 60px;
		}
	}
}
</style>
